import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { FastSignInGlobalComponent } from '../fast-sign-in-global.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'hop-dialog-register-client',
  template: `<div class="">
    <div class="flex justify-between">
      <!--<h1 *ngIf="data?.type=='register'" mat-dialog-title class="text-sm sm:text-lg">{{ '_menu-user.register' |
      translate }}</h1>
    <h1 *ngIf="data?.type=='login'" mat-dialog-title class="text-sm sm:text-lg">{{ '_menu-user.login' | translate
      }}</h1>-->
      <h1 mat-dialog-title class="text-sm sm:text-lg">{{ 'sign-in' | translate }}</h1>
      <div mat-dialog-actions>
        <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
      </div>
    </div>
    <div mat-dialog-content>
      <hop-fast-sign-in-global></hop-fast-sign-in-global>
    </div>
  </div> `,
  styleUrls: ['./dialog-register-client.component.scss'],
  imports: [FastSignInGlobalComponent, MatIconModule, MatButtonModule, MatDialogModule, TranslateModule]
})
export class DialogRegisterClientComponent implements OnInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  destroyRef = inject(DestroyRef);

  constructor(
    public dialogRef: MatDialogRef<DialogRegisterClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      if (user) {
        this.dialogRef.close();
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
