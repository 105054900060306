import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/@hop/state';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from 'src/@hop/services';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UserEmailCodeComponent } from '../user-email-code/user-email-code.component';
import { ProfileService } from 'src/@hop/services/profile.service';
import { AddPhoneComponent } from '../add-phone/add-phone.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'dialog-add-phone',
  template: `
    <div class="">
      <div class="flex justify-between">
        <h1 mat-dialog-title class="text-lg">{{ '_edit_phone_email.add-new-phone-number' | translate }}</h1>
        <div mat-dialog-actions>
          <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
        </div>
      </div>
      <div mat-dialog-content>
        <add-phone (onAddPhone)="addPhone()"></add-phone>
      </div>
    </div>
  `,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslateModule, AddPhoneComponent, MatDialogModule]
})
export class DialogAddPhoneComponent {
  user: UserModel;
  form: UntypedFormGroup;
  email: string;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  protected preferredCountries: string[];
  destroyRef = inject(DestroyRef);

  constructor(
    public dialogRef: MatDialogRef<AddPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private formUtilsService: FormUtilsService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private store: Store,
    @Inject('environment') private environment: any
  ) {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.user = user;
      this.email = user?.email;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addPhone() {
    this.dialogRef.close();
  }
}
