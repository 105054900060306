import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from 'src/@hop/services';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileService } from 'src/@hop/services/profile.service';
import { PhoneInputComponent } from 'hop-calendar';
import { AddEmailComponent } from '../add-email/add-email.component';

@Component({
  selector: 'dialog-add-email',
  template: `
    <div class="">
      <div class="flex justify-between">
        <h1 mat-dialog-title class="text-lg">{{ '_edit_phone_email.add-new-email-address' | translate }}</h1>
        <div mat-dialog-actions>
          <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
        </div>
      </div>
      <div mat-dialog-content>
        <add-email (onAddEmail)="addEmail()"></add-email>
      </div>
    </div>
  `,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TranslateModule, MatDialogModule, PhoneInputComponent, AddEmailComponent]
})
export class DialogAddEmailComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogAddEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileService: ProfileService,
    private formUtilsService: FormUtilsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  addEmail() {
    this.dialogRef.close();
  }
}
