import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { UserProfileModel } from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { DialogContactFormComponent } from '../contact-form/dialog-contact-form.component';

@Component({
  selector: 'hop-social-buttons',
  template: `
    @if (userProfile?.whatsApp) {
      <button mat-icon-button color="primary" (click)="goToWhatsApp(userProfile?.whatsApp)">
        <mat-icon svgIcon="logo:whatsapp"></mat-icon>
      </button>
    }
    @if (userProfile?.signal) {
      <button mat-icon-button color="primary" (click)="goToSignal(userProfile?.signal)">
        <mat-icon svgIcon="logo:signal"></mat-icon>
      </button>
    }
    @if (userProfile?.telegram) {
      <button mat-icon-button color="primary" (click)="goToTelegram(userProfile?.telegram)">
        <mat-icon svgIcon="logo:telegram"></mat-icon>
      </button>
    }
    @if (userProfile?.phone) {
      <button mat-icon-button color="primary" (click)="goToPhone(userProfile?.phone)">
        <mat-icon svgIcon="mat:phone"></mat-icon>
      </button>
    }

    @if (userProfile?.youtube) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.youtube)">
        <mat-icon svgIcon="logo:youtube"></mat-icon>
      </button>
    }
    @if (userProfile?.linkedInUrl) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.linkedInUrl)">
        <mat-icon svgIcon="logo:linkedin"></mat-icon>
      </button>
    }
    @if (userProfile?.twitterUrl) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.twitterUrl)">
        <mat-icon svgIcon="social:x"></mat-icon>
      </button>
    }
    @if (userProfile?.instagramUrl) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.instagramUrl)">
        <mat-icon svgIcon="logo:instagram"></mat-icon>
      </button>
    }
    @if (userProfile?.facebookUrl) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.facebookUrl)">
        <mat-icon svgIcon="logo:facebook"></mat-icon>
      </button>
    }
    @if (userProfile?.addressUrl) {
      <button mat-icon-button color="primary" (click)="goToLink(userProfile?.addressUrl)">
        <mat-icon>location_pin</mat-icon>
      </button>
    }
    <!-- <button mat-icon-button color="primary" (click)="contactProfessional()">
    <mat-icon>email</mat-icon>
    </button> -->
  `,
  styles: [``],
  providers: [{ provide: MatFormFieldControl, useExisting: SocialButtonsComponent }],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, MatButtonModule, MatIconModule]
})
export class SocialButtonsComponent implements OnInit {
  @Input() userProfile: UserProfileModel;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  goToWhatsApp(phone: string) {
    const formattedPhone = phone.replace(/^\+/, '');
    window.open(`https://wa.me/${formattedPhone}`, '_blank');
  }

  goToSignal(phone: string) {
    const formattedPhone = phone.replace(/^\+/, '');
    window.open(`https://signal.me/#p/${formattedPhone}`, '_blank');
  }

  goToTelegram(username: string) {
    window.open(`https://t.me/${username}`, '_blank');
  }

  goToPhone(phone: string) {
    window.open(`tel:${phone}`, '_blank');
  }

  goToLink(link: string) {
    window.open(link, '_blank');
  }

  contactProfessional() {
    this.dialog.open(DialogContactFormComponent, {
      panelClass: 'dialog-open-menu-action'
    });
  }
}
