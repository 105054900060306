import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, effect, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { UserModel } from '../../../../models/user';
import { AuthenticationService, LayoutService } from 'src/@hop/services';
import { Store } from '@ngxs/store';
import { UserLogout } from 'src/@hop/state/actions/user.action';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSetPasswordComponent } from '../dialog-set-password.component';
import { AppConfigService } from 'src/@hop/services/app-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'hop-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit, OnDestroy {
  user: UserModel;
  // @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  items: MenuItem[] = [
    {
      id: 'dashboard',
      icon: 'logo:logo',
      label: 'Dashboard',
      description: 'See your dashboard',
      colorClass: 'text-primary',
      route: '/dashboard'
    },
    {
      id: 'account-settings',
      icon: 'mat:settings',
      label: 'Account Settings',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/account'
    },
    {
      id: 'my-profile',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Edit your account page',
      colorClass: 'text-primary',
      route: '/public-profile'
    },
    {
      id: 'my-integrations',
      icon: 'mat:code',
      label: 'My Integrations',
      description: 'Edit your account page',
      colorClass: 'text-primary',
      route: '/account/integrations'
    },
    {
      id: 'my-connected-calendars',
      icon: 'mat:date_range',
      label: 'My Connected Calendars',
      description: 'Manage my connected calendars',
      colorClass: 'text-primary',
      route: '/account/connected-calendars'
    }
    // {
    //   id: '3',
    //   icon: 'mat:man',
    //   label: 'About You',
    //   description: 'Informations about yourself',
    //   colorClass: 'text-amber',
    //   route: '/about-you'
    // },
    // {
    //   id: '4',
    //   icon: 'mat:table_chart',
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  destroyRef = inject(DestroyRef);

  constructor(
    protected authService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private store: Store,
    private translateService: TranslateService,
    @Inject('environment') public environment,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    public appConfigService: AppConfigService
  ) {
    // this.features$.subscribe((features) => {
    //   this.isEnabledWizard = features?.isEnabledWizard?.value;
    // });
    this.user = this.popoverRef.data.user;
    if (!this.user?.isProfessional) {
      this.items = [
        {
          id: 'dashboard',
          icon: 'logo:logo',
          label: 'Dashboard',
          description: 'See your dashboard',
          colorClass: 'text-primary',
          route: '/dashboard'
        },
        {
          id: 'client-appointments',
          icon: 'mat:calendar_today',
          label: 'Appointments',
          description: 'See your client appointments',
          colorClass: 'text-primary',
          route: '/client-appointments'
        },
        {
          id: 'client-orders',
          icon: 'mat:shopping_cart',
          label: 'Orders',
          description: 'See your client orders',
          colorClass: 'text-primary',
          route: '/client-orders'
        },
        {
          id: 'my-profile',
          icon: 'mat:account_circle',
          label: 'My Profile',
          description: 'Edit your account page',
          colorClass: 'text-primary',
          route: '/public-profile'
        }
      ];
    }
    this.translateService
      .get([
        'account-settings',
        'personal-info',
        'my-public-profile',
        'my-public-profile-description',
        'my-integrations',
        'my-integrations-description',
        'my-connected-calendars',
        'my-connected-calendars-description',
        '_menu.dashboard',
        '_menu.content.my-orders',
        '_menu.content.my-appointments',
        '_general.go-to-dashboard',
        '_general.go-to-my-orders',
        '_general.go-to-my-appointments'
      ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((translations) => {
        const dashboard = this.items.find((item) => item.id === 'dashboard');
        const accountSettings = this.items.find((item) => item.id === 'account-settings');
        const myProfile = this.items.find((item) => item.id === 'my-profile');
        const myIntegrations = this.items.find((item) => item.id === 'my-integrations');
        const myConnectedCalendars = this.items.find((item) => item.id === 'my-connected-calendars');
        const clientOrders = this.items.find((item) => item.id === 'client-orders');
        const clientAppointments = this.items.find((item) => item.id === 'client-appointments');
        dashboard.label = translations['_menu.dashboard'];
        dashboard.description = translations['_general.go-to-dashboard'];
        if (accountSettings) {
          accountSettings.label = translations['account-settings'];
          accountSettings.description = translations['personal-info'];
        }
        if (myProfile) {
          myProfile.label = translations['my-public-profile'];
          myProfile.description = translations['my-public-profile-description'];
        }
        if (clientOrders) {
          clientOrders.label = translations['_menu.content.my-orders'];
          clientOrders.description = translations['_general.go-to-my-orders'];
        }
        if (clientAppointments) {
          clientAppointments.label = translations['_menu.content.my-appointments'];
          clientAppointments.description = translations['_general.go-to-my-appointments'];
        }
        if (myIntegrations) {
          myIntegrations.label = translations['my-integrations'];
          myIntegrations.description = translations['my-integrations-description'];
        }
        if (myConnectedCalendars) {
          myConnectedCalendars.label = translations['my-connected-calendars'];
          myConnectedCalendars.description = translations['my-connected-calendars-description'];
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }
  logout() {
    this.store.dispatch(new UserLogout());
  }

  switchUser(isProfessional: boolean) {
    this.authService.switchUser(isProfessional).subscribe(() => {
      window.location.reload();
    });
  }
  openSetPasswordDialog() {
    this.dialog
      .open(DialogSetPasswordComponent, {
        panelClass: 'dialog-open-menu-action'
      })
      .afterClosed()
      .subscribe((data) => {
        if (data?.isPasswordSet) {
          window.location.reload();
        }
      });
  }

  showOrHideWizard(toBeEnabledWizard: boolean) {
    this.appConfigService.setUserConfig({ name: 'isDisabledWizard', value: { value: !toBeEnabledWizard } }).subscribe();
    if (!toBeEnabledWizard) {
      this.layoutService.closeCompleteAccount();
    } else {
      this.layoutService.openCompleteAccount();
    }
  }
}
