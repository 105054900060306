import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { FormUtilsService, UserService } from 'src/@hop/services';
import { UserState } from 'src/@hop/state';
import { DialogAddPhoneComponent } from './dialog-add-phone.component';
import { DialogAddEmailComponent } from './dialog-add-email.component';
import { DialogDeletePhoneComponent } from './dialog-delete-phone.component';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hop-names',
  templateUrl: './names.component.html',
  styleUrls: ['./names.component.scss']
})
export class NamesComponent implements OnInit {
  form: UntypedFormGroup | UntypedFormArray | UntypedFormControl | any;
  submitted = false;
  user: UserModel;

  @Output() formSubmit = new EventEmitter();

  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
    destroyRef = inject(DestroyRef);

  constructor(
    private formUtilsService: FormUtilsService,
    private dialog: MatDialog,
    public translateService: TranslateService
  ) {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.user = { ...user };
      delete this.user.writeCalendar;
      if (!this.form) {
        const user = { ...this.user };
        if (user?.isGuest) {
          user._termsAccepted = false;
        }
        this.form = this.formUtilsService.createFormFromObject(user);
        this.form.controls.email.disable();
        this.form.controls.phone.disable();
        if (user?.isGuest) {
          this.form.controls._termsAccepted.setValidators([Validators.requiredTrue]);
        }
        this.form.controls.username.setValidators([Validators.required]);
        this.form.controls.lastName.setValidators([Validators.required]);
        this.form.controls.firstName.setValidators([Validators.required]);

        this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
          this.submitted = false;
        });
      } else {
        this.form.setValue(this.user);
        this.form.markAsPristine();
      }
    });
  }

  ngOnInit() {}

  submit() {
    this.formSubmit.next(this.form);
  }
  deletePhoneNumber() {
    this.dialog.open(DialogDeletePhoneComponent, {
      panelClass: 'dialog-open-menu-action'
    });
  }
  addPhoneNumber() {
    this.dialog.open(DialogAddPhoneComponent, {
      panelClass: 'dialog-open-menu-action'
    });
  }
  addEmail() {
    this.dialog.open(DialogAddEmailComponent, {
      panelClass: 'dialog-open-menu-action'
    });
  }
}
