import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class routeDataResolver implements Resolve<any>, OnDestroy {
  //cleanUrl = state.url.split('?')[0];

  slug = '';
  constructor() {}
  ngOnDestroy(): void {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.slug = 'page/' + route.url;
    return of(this.slug);
  }
}
