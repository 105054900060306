import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { AuthenticationService } from 'src/@hop/services';
import { UserGet, UserState } from 'src/@hop/state';

@Component({
  selector: 'hop-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  form: UntypedFormGroup;
  email;
  @Output() emailVerified = new EventEmitter();
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      verifyEmail: ['', Validators.required]
    });
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.email = user.email;
    });
  }
  destroyRef = inject(DestroyRef);
  resendCode() {
    this.authService.resendEmailCode(this.f['verifyEmail'].value).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  submit() {
    this.authService
      .verifyEmail(this.f['verifyEmail'].value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // setTimeout(() => {
        this.store.dispatch(new UserGet());
        this.emailVerified.emit(true);
        // }, 2000);
      });
  }

  get f() {
    return this.form.controls;
  }
}
