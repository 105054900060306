import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppHopState, LanguageSet } from 'src/@hop/state';
import { AppState } from '../../../app/state/states/app-state.service';
import { Language } from '../../models/language-model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hop-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageMenuComponent implements OnInit {
  @Select(AppHopState.selectLanguage) language$: Observable<any>;
  @Select(AppState.selectAvailableLanguages) selectAvailableLanguages$: Observable<Language[]>;
  @Input() manyAdditionalLangs: Language[] = null;
  nameLangFlag = '🇬🇧';
  @Input() showName: boolean;
  selectAvailableLanguages: Language[];
  destroyRef = inject(DestroyRef);
  constructor(private store: Store) {
    this.language$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((language) => {
      if (language?.flag) {
        this.nameLangFlag = language.flag;
      }
    });
    this.selectAvailableLanguages$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((languages) => {
      this.selectAvailableLanguages = languages;
    });
  }

  ngOnInit(): void {}

  selectLang(lang: Language) {
    this.store.dispatch(new LanguageSet({ ...lang, isManuallySet: true }));
  }
}
