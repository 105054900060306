import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DynamicComponentsService } from '../data-access/dynamic-component.service';
import { isFulfilled } from '../utils/utils';
import { ComponentTemplate, LoadedRenderItems } from './render-template.types';
import { getDiff } from 'recursive-diff';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-render-template',
  template: ` <ng-template #container></ng-template> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderTemplateComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) components: ComponentTemplate[] | ComponentTemplate;

  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;

  private componentRefs: ComponentRef<any>[] = [];
  private init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private cdr: ChangeDetectorRef,
    private dynamicComponentsService: DynamicComponentsService
  ) {}

  ngOnDestroy() {
    this.componentRefs.forEach((ref) => ref.destroy());
    if (this.container) {
      this.container.clear();
    }
  }
  destroyRef = inject(DestroyRef);
  ngAfterViewInit() {
    this.init$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.container || !this.components || this.components.length === 0) {
        return;
      }

      if (!Array.isArray(this.components)) {
        this.components = [this.components];
      }

      this.componentRefs.forEach((ref) => ref.destroy()); // clear all refs

      const loadedComponentModules = this.components
        .filter((componentData) => this.dynamicComponentsService.checkComponentMap(componentData, 'dev'))
        .filter((componentTemplate) => !componentTemplate.isDisabled)
        .map(async (componentTemplate) => {
          const itemRef = await this.dynamicComponentsService.loadComponentConstructor(componentTemplate.selector);
          return { renderItemRef: itemRef, componentTemplate };
        });

      this.container?.clear(); // clear the container that holds the components
      this.renderComponents(loadedComponentModules);
    });
  }

  async renderComponents(items: Promise<LoadedRenderItems>[]) {
    const allSettledItems = await Promise.allSettled(items);
    for (let item of allSettledItems) {
      if (isFulfilled(item)) {
        const newComponent = this.dynamicComponentsService.createComponent(this.container, item.value.componentTemplate, item.value.renderItemRef);
        if (newComponent) {
          this.componentRefs.push(newComponent);
        }
      } else {
        // is rejected
        console.error(item.reason);
      }
    }
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['components'] && changes['components'].currentValue) {
      const diff = getDiff(changes['components'].previousValue, changes['components'].currentValue);
      if (diff.length) {
        this.init$.next(true);
      }
    }
  }
}
