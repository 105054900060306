import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError, debounceTime, distinctUntilChanged, combineLatest, filter, BehaviorSubject } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { CachingService } from './caching.service';
import { ActivationStart, NavigationStart, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AppHopState } from '../state';
import { LanguageModel } from '../models/language.model';
import { UserProfileModel } from '../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Injectable({ providedIn: 'root' })
export class PublicProfileService {
  endpoint = `${this.environment.apiUrl}/public-profile`;
  @Select(AppHopState.selectLanguage) language$: Observable<LanguageModel>;
  userProfile$: BehaviorSubject<UserProfileModel> = new BehaviorSubject<UserProfileModel>(null);
  private _username$ = this.router.events.pipe(
    debounceTime(50),
    map((event: ActivationStart) => {
      const match = window.location.pathname.match(/^\/?@([a-zA-Z0-9][a-zA-Z0-9-_]+[a-zA-Z0-9])+[\/?]?(.+)?/);
      const username = match ? match[1] : null;
      return username;
    })
  );

  username$ = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    private cachingService: CachingService,
    private router: Router
  ) {
    this._username$.subscribe((username) => {
      this.username$.next(username);
    });
    combineLatest([this.username$, this.language$])
      .pipe(
        debounceTime(100),
        distinctUntilChanged((prev, curr) => prev[0] === curr[0] && prev[1].code === curr[1].code)
      )
      .subscribe(([username, lang]) => {
        if (!username) {
          this.userProfile$.next(null);
          return;
        }
        this.getUserPublicProfile(username, lang?.code).subscribe({
          next: (userProfileInfo) => {
            this.userProfile$.next({ ...userProfileInfo });
          },
          error: (error) => {
            if (error?.error) {
              // this.toastr.error('Profile not found', '', { timeOut: 10000, progressBar: true });
            }
          }
        });
      });
  }
  get(langCode?: string): Observable<any> {
    let params = new HttpParams();

    if (langCode) {
      params = params.set('lang', langCode);
    }
    return this.http.get<any>(this.endpoint, { params }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  getUserPublicProfile(username: string, lang = '', name = '', cacheMin = 0.5): Observable<any> {
    return this.cachingService.remember(
      'publicProfile' + name + username + lang,
      this.http.get<any>(`${this.endpoint}/username/${username}`, { params: { lang } }).pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      ),
      !cacheMin,
      cacheMin
    );
  }

  getByUsername(username: string): Observable<any> {
    return this.http.get<any>(this.endpoint + '/username/' + username).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  putPublicProfile(profile: PublicProfileModel): Observable<any> {
    if (profile.uuid) {
      return this.http.patch<any>(this.endpoint, profile).pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
    }
    return this.http.put<any>(this.endpoint, profile).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  removePublicProfile(uuid: string): Observable<any> {
    return this.http.delete<any>(this.endpoint, { body: { uuid: uuid } }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
