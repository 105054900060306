import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@hop/services';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@hop/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@hop/config/config.service';
import { SidebarComponent } from '../../@hop/components/sidebar/sidebar.component';
import { FeaturesState, UserState } from '../../@hop/state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FeatureEnum, FeaturesModel, UserModel } from '../../@hop/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hop-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  destroyRef = inject(DestroyRef);

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map((config) => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;
  isMedium$ = this.layoutService.gtMd$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit() {
    this.layoutService.configpanelOpen$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((open) => (open ? this.configpanel.open() : this.configpanel.close()));
  }

  protected readonly FeatureEnum = FeatureEnum;
}
