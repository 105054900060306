import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService, FormUtilsService } from 'src/@hop/services';
import { fadeInUp400ms } from '../../../../../../@hop/animations/fade-in-up.animation';
import { ResponseErrorModel } from '../../../../../../@hop/models/responseErrorModel';

import { environment } from 'src/environments/environment';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormErrorsModule } from '../../../../../../@hop/components/mat-form-errors/mat-form-errors.module';
import { PasswordStrengthMeterModule } from '../../../../../../@hop/components/password-strength-meter/password-strength-meter.module';
import { UserPhoneCodeComponent } from '../../../../../../@hop/components/user-phone-code/user-phone-code.component';
import * as cookie from 'js-cookie';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'hop-register-phone',
  standalone: true,
  template: `
    <div [formGroup]="form" class="flex flex-col gap-4 flex-auto">
      <div>
        <mat-form-field class="w-full">
          <mat-label>{{ '_label.phone' | translate }}</mat-label>
          <input formControlName="phone" matInput (click)="clickPhone()" [readonly]="!!phone" id="phone" />
          <mat-error>
            <hop-mat-form-error control="phone"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ 'firstName' | translate }}</mat-label>
          <input formControlName="firstName" matInput #firstNameRef id="firstName" />
          <mat-error>
            <hop-mat-form-error control="firstName"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ 'lastName' | translate }}</mat-label>
          <input formControlName="lastName" matInput id="lastName" />
          <mat-error>
            <hop-mat-form-error control="lastName"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        @if (form?.controls['username']) {
          <mat-form-field class="w-full">
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input formControlName="username" matInput id="username" />
            <mat-error>
              <hop-mat-form-error control="username"></hop-mat-form-error>
            </mat-error>
          </mat-form-field>
        }

        <mat-form-field class="w-full">
          <mat-label>{{ '_label.phone-code' | translate }}</mat-label>
          <hop-user-phone-code #userPhoneCodeRef [phoneToSend]="phone" formControlName="phoneCode"></hop-user-phone-code>
          <mat-error>
            <hop-mat-form-error control="phoneCode"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="">
        <mat-checkbox data-testid="hop-register-phone-termsAccepted" formControlName="termsAccepted" class="caption">
          <span [innerHTML]="translateService.instant('_account._register.i-accept-the-terms')"></span>
        </mat-checkbox>
        @if (form.get('termsAccepted').hasError('required') && form.get('termsAccepted').touched) {
          <mat-error>{{ '_account._register.required-to-accept' | translate }}</mat-error>
        }
      </div>
      <button data-testid="button-sign-in" (click)="send()" [disabled]="loading" color="primary" mat-raised-button type="button">
        {{ 'sign-in' | translate }}
      </button>
    </div>
  `,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    TranslatePipe,
    MatFormErrorsModule,
    PasswordStrengthMeterModule,
    UserPhoneCodeComponent
  ],
  //styleUrls: ['./register-phone.component.scss'],
  animations: [fadeInUp400ms]
})
export class RegisterPhoneComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  environment = environment;
  @Input() phone = '';
  @Output() phoneClicked = new EventEmitter();
  @ViewChild('firstNameRef', { static: true }) firstNameRef: ElementRef;
  @ViewChild('userPhoneCodeRef', { static: true }) userPhoneCodeRef: UserPhoneCodeComponent;
  @Input() isProfessional = false;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private formUtilsService: FormUtilsService,
    public translateService: TranslateService
  ) {}

  sentOnce = false;
  sentPhoneCode = false;
  destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      phone: [this.phone, [Validators.required]],
      phoneCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      termsAccepted: [false, Validators.requiredTrue]
    });
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000)).subscribe((data) => {
      if (!this.sentPhoneCode && this.form.controls['phone'].valid && data.firstName) {
        this.sentPhoneCode = true;
        this.userPhoneCodeRef.sendPhoneCode();
      }
      if (!this.sentOnce && this.form.valid && data.phoneCode.length === 6) {
        this.send();
      }
    });
    if (cookie.get('usernameReserved')) {
      this.form.addControl(
        'username',
        this.fb.control(cookie.get('usernameReserved'), [Validators.required, Validators.minLength(4), Validators.maxLength(15)])
      );
    }
  }

  ngAfterViewInit(): void {}

  loading = false;
  send() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      //this.userPhoneCodeRef.stateChanges.next();
      return;
    } else {
      let options: any = {
        firstName: this.f['firstName']?.value,
        lastName: this.f['lastName']?.value,
        isProfessional: this.isProfessional,
        termsAccepted: this.f['termsAccepted']?.value
      };
      if (cookie.get('usernameReserved')) {
        options.username = cookie.get('usernameReserved');
      }
      this.loading = true;
      this.sentOnce = true;
      this.authService
        .registerPhone(this.f['phone']?.value, this.f['phoneCode']?.value, options)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            cookie.remove('usernameReserved');
          },
          error: (error: { error: ResponseErrorModel }) => {
            this.loading = false;
            if (error.error.errors) {
              this.formUtilsService.setFormErrors(error.error, this.form);
            }
          },
          complete: () => {
            this.loading = false;
          }
        });
    }
  }

  get f() {
    return this.form.controls;
  }

  clickPhone() {
    this.phoneClicked.emit(true);
  }
}
