import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SetPasswordModule } from '../../set-password/set-password.module';
import { TranslateModule } from '@ngx-translate/core';
import { VerifyEmailModule } from 'src/@hop/components/verify-email/verify-email.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'hop-dialog-register-client',
  templateUrl: './dialog-menu-complete-account.component.html',
  styleUrls: ['./dialog-menu-complete-account.component.scss'],
  imports: [SetPasswordModule, MatIconModule, MatButtonModule, TranslateModule, MatDialogModule, VerifyEmailModule]
})
export class DialogCompleteAccountComponent implements OnInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  constructor(
    public dialogRef: MatDialogRef<DialogCompleteAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      if (this.data?.setPassword) {
        if (!user?.needsPassword) {
          this.dialogRef.close();
        }
      } else if (this.data?.verifyEmail) {
        if (user?.isEmailVerified) {
          this.dialogRef.close();
        }
      }
    });
  }
}
