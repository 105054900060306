import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode, SecurityContext } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HopModule } from '../@hop/hop.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './_helpers';
import { Router } from '@angular/router';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { HopDebugToolModule } from '../@hop/components/hop-debug-tool/hop-debug-tool.module';
import { NgxsDevelopmentModule, NgxsModule } from '@ngxs/store';
import { AuthenticationService } from '../@hop/services';
import { environment } from '../environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AppState } from './state/states/app-state.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiInterceptor } from './_helpers/api.interceptor';
import { IfFeatureFlagDirective } from '../@hop/components/is-feature/is-feature.directive';
import { AppUpdateService, ChatWootService } from './services';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';
import { CredentialsInterceptor } from './_helpers/credentials.interceptor';
//import { VerifyEmailInfoModule } from 'src/@hop/components/verify-email-info/verify-email-info.module';
import { ToastrInterceptor } from './_helpers/toastr.interceptor';
import { LocationStrategy } from '@angular/common';
import { LangPreserveQueryLocationStrategy } from '../@hop/_helpers/langPreserveQuery';
import { PasswordStrengthMeterModule } from '../@hop/components/password-strength-meter/password-strength-meter.module';
import * as Sentry from '@sentry/angular';
import { CalendarModule, DateAdapter } from 'hop-calendar';

import { adapterFactory } from 'hop-calendar/date-adapters/date-fns';
import { CustomLayoutPublicModule } from './custom-layout-public/custom-layout-public.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './mat-paginator-intl';
import { MatButtonModule } from '@angular/material/button';
import { HttpConfigInterceptor } from './_helpers';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { LOADING_BAR_CONFIG, LoadingBarModule } from '@ngx-loading-bar/core';
import { GlobalErrorHandler } from './_helpers/global-error-handler';
import { CheckInternetConnectionComponent } from '../@hop/components/check-internet-connection/check-internet-connection.component';
import { WebsocketService } from '../@hop/services/websocket.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AppConfigService } from '../@hop/services/app-config.service';
import { CookieComponent } from './pages/pages/cookie-accept-component/cookie.component';
import { DirtyFormGuard } from './_helpers/dirty-form-guard.guard';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const config: SocketIoConfig = {
  url: environment.mainUrl,
  options: {
    autoConnect: true,
    reconnection: true
  }
};
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
      // environment
    }),
    NgxsStoragePluginModule.forRoot({ keys: '*' }),
    NgxsModule.forRoot([AppState]),
    NgxsLoggerPluginModule.forRoot({ collapsed: false, disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsDevelopmentModule.forRoot({
      warnOnUnhandledActions: true // <-- set this flag to 'true' will warn for any unhandled actions
    }),
    TranslateModule.forRoot({
      // defaultLangCodeuage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // Hop
    HopModule.forRoot(environment),
    CustomLayoutModule,
    CustomLayoutPublicModule,
    HopDebugToolModule,
    //VerifyEmailInfoModule,
    MatButtonModule,
    HopDebugToolModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IfFeatureFlagDirective,
    MatSnackBarModule,
    // MatInputModule
    ToastrModule.forRoot(),
    PasswordStrengthMeterModule.forRoot(),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true
        }
      }
    }),
    // for HttpClient use:
    //LoadingBarHttpClientModule,
    // for Router use:
    //LoadingBarRouterModule,
    LoadingBarModule,
    CheckInternetConnectionComponent,
    SocketIoModule.forRoot(config),
    CookieComponent
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, AppConfigService, HttpClient] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ToastrInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    AppUpdateService,
    { provide: LocationStrategy, useClass: LangPreserveQueryLocationStrategy },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    /*{
          provide: NGXS_PLUGINS,
          useValue: openReplayPlugin,
          multi: true
        },*/
    ChatWootService,
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 500 } },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    WebsocketService,
    provideHttpClient(withInterceptorsFromDi()),
    DirtyFormGuard
  ]
})
export class AppModule {}

export function openReplayPlugin(state, action, next) {
  // Use the get action type helper to determine the type
  /*if (getActionTypeFromInstance(action) === UserLogout.type) {
    // if we are a logout type, lets erase all the state

    state = {};
  }*/

  // return the next function with the empty state
  //
  return next(state, action);
}
