import { Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { filter } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'hop-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  show$ = this.layoutService.searchOpen$;
  searchCtrl = new UntypedFormControl();

  @ViewChild('searchInput', { static: true }) input: ElementRef;

  constructor(
    private layoutService: LayoutService,
    private searchService: SearchService
  ) {}

  destroyRef = inject(DestroyRef);
  ngOnInit() {
    this.searchService.isOpenSubject.next(true);
    this.searchCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => this.searchService.valueChangesSubject.next(value));

    this.show$
      .pipe(
        filter((show) => show),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.input.nativeElement.focus());
  }

  close() {
    this.layoutService.closeSearch();
    this.searchCtrl.setValue(undefined);
    this.searchService.isOpenSubject.next(false);
  }

  search() {
    this.searchService.submitSubject.next(this.searchCtrl.value);
    this.close();
  }

  ngOnDestroy(): void {
    this.layoutService.closeSearch();
    this.searchCtrl.setValue(undefined);
    this.searchService.isOpenSubject.next(false);
  }
}
