import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimeUtilsService } from 'src/@hop/services/timeUtils.service';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CardDashboardModule } from 'src/app/pages/pages/member/card-dashboard/card-dashboard.module';
import { FullReviewComponentModule } from 'src/app/pages/pages/review/full-review-component/full-review-component.module';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatFormErrorsModule } from '../mat-form-errors/mat-form-errors.module';
import { MatInputModule } from '@angular/material/input';
import { UserModel } from 'src/@hop/models';
import { FastSignInGlobalComponent } from '../../../app/pages/pages/auth/fast-sign-in-global.component';
import { FormUtilsService, UserService } from 'src/@hop/services';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  imports: [
    CardDashboardModule,
    MatPaginatorModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FullReviewComponentModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatFormErrorsModule,
    MatInputModule,
    FastSignInGlobalComponent
  ],
  standalone: true,
  selector: 'hop-contact-form',
  template: `
    <div class="flex flex-col space-y-5">
      @if (!user) {
        <hop-fast-sign-in-global></hop-fast-sign-in-global>
      }
      @if (user) {
        <div [formGroup]="form" class="flex flex-col space-y-2">
          <mat-form-field class="">
            <input placeholder="{{ '_contact-form.subject' | translate }}" data-testid="input-contact-subject" matInput formControlName="subject" />
            <mat-error><hop-mat-form-error control="subject"></hop-mat-form-error></mat-error>
          </mat-form-field>
          <mat-form-field class="">
            <textarea
              rows="5"
              placeholder="{{ '_contact-form.message' | translate }}"
              data-testid="textarea-contact-message"
              matInput
              formControlName="message"
            >
            </textarea>
            <mat-error><hop-mat-form-error control="message"></hop-mat-form-error></mat-error>
          </mat-form-field>
          <button (click)="sendMessage()" mat-raised-button color="primary" type="submit" class="self-end">
            {{ '_contact-form.send-message' | translate }}
          </button>
        </div>
      }
    </div>
  `
})
export class ContactFormComponent implements OnInit {
  form: UntypedFormGroup;
  professionalUsername: string;
  @Input() user: UserModel = null;
  @Input() professionalUser: any = null;
  @Output() onSendMessage = new EventEmitter();
  constructor(
    public timeUtilsService: TimeUtilsService,
    public translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private usersService: UserService,
    private toastr: ToastrService,
    private formUtilsService: FormUtilsService
  ) {}
  async ngOnInit() {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }
  destroyRef = inject(DestroyRef);

  sendMessage() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error(this.translateService.instant('_general.check_form_for_errors'), this.translateService.instant('_general.error'), {
        timeOut: 3000,
        progressBar: true
      });
      return;
    }
    this.usersService
      .sendMessageContactForm(this.professionalUser?.user?.username, this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result) => {
          this.onSendMessage.emit();
          this.form.reset();
        },
        error: (error) => {
          if (error.error) {
            this.form.markAllAsTouched();
            this.toastr.error(this.translateService.instant('_general.check_form_for_errors'), this.translateService.instant('_general.error'), {
              timeOut: 3000,
              progressBar: true
            });
            //this.form.controls?.customUrl?.setValue(`${this.environment.mainUrl}/@${this.user.username}/o/` + this.form?.value?.customUrl);
            this.formUtilsService.setFormErrors(error.error, this.form);
          }
        }
      });
  }
}
