import { Component, DestroyRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { AppointmentService } from '../../services/appointment.service';
import { AppointmentModel } from '../../models/appointment.model';
import { MatIconModule } from '@angular/material/icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'hop-reserved-timer',
  standalone: true,
  template: `
    @if (dateReservedUntilString) {
      <div id="countdown" class="cursor-pointer" [matMenuTriggerFor]="infoBody">
        <svg>
          <circle r="10" cx="12" cy="12" [attr.stroke]="circleColor" [attr.stroke-dashoffset]="circleDashoffset"></circle>
        </svg>
        <div id="countdown-number">{{ remainingMinutes }}</div>
      </div>
    }
    <mat-menu #infoBody="matMenu" xPosition="before">
      <p>{{ '_appointment._help.reserved-timer' | translate: { remainingMinutes } }}</p>
      @if (appointmentUuid) {
        <button mat-flat-button (click)="extendReservation(appointmentUuid)">
          <mat-icon>restart_alt</mat-icon>
          {{ '_appointment._help.reset-timer' | translate }}
        </button>
      }
    </mat-menu>
  `,
  imports: [MatMenuModule, TranslateModule, MatButtonModule, MatIconModule],
  styles: [
    `
      #countdown {
        position: relative;
        margin: auto;
        height: 24px;
        width: 24px;
        text-align: center;
      }

      #countdown-number {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: inline-block;
        line-height: 24px;
        font-size: 14px;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        transform: rotateY(-180deg) rotateZ(-90deg);
      }

      svg circle {
        stroke-dasharray: 62.8px;
        stroke-linecap: round;
        stroke-width: 2px;
        fill: none;
      }
    `
  ]
})
export class ReservedTimerComponent implements OnChanges {
  @Input() dateReservedUntilString: string;
  @Input() totalTimeMinutes = 60;
  @Input() appointmentUuid: string;
  remainingMinutes = 0;
  circleColor = 'green';
  circleDashoffset = 62.8;

  private countdownSubscription: Subscription;

  constructor(private appointmentService: AppointmentService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateReservedUntilString) {
      this.startCountdown();
    }
  }
  destroyRef = inject(DestroyRef);

  startCountdown() {
    // Unsubscribe from the previous countdown if it exists
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    const countdown$ = interval(1000).pipe(
      map((elapsed) => {
        const dateReservedUntil = new Date(this.dateReservedUntilString);
        const now = new Date();
        const totalSeconds = this.totalTimeMinutes * 60;
        const remainingSeconds = (dateReservedUntil.getTime() - now.getTime()) / 1000;
        this.remainingMinutes = Math.floor(remainingSeconds / 60) < 0 ? 0 : Math.floor(remainingSeconds / 60);
        return remainingSeconds > 0 ? remainingSeconds : 0;
      }),
      takeWhile((remaining) => remaining >= 0)
    );

    this.countdownSubscription = countdown$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((remaining) => {
      const percentage = (remaining / (this.totalTimeMinutes * 60)) * 100;
      this.circleDashoffset = (percentage * 62.8) / 100;
      //console.log(remaining, this.totalTimeMinutes + ' min', this.remainingMinutes, percentage + '%', this.circleDashoffset);

      if (percentage < 10) {
        this.circleColor = 'red';
      } else if (percentage < 50) {
        this.circleColor = 'orange';
      } else {
        this.circleColor = 'green';
      }
    });
  }

  extendReservation(appointmentUuid: string) {
    this.appointmentService.extendReservation(appointmentUuid).subscribe((appointment: any) => {
      this.dateReservedUntilString = appointment._dateAddedToOrderReservedUntil;
    });
  }
}
