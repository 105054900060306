import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from '../../state';
import { Meta } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { UserModel, UserProfileModel } from 'src/@hop/models';
import { DialogRegisterClientComponent } from 'src/app/pages/pages/auth/dialog-register-client/dialog-register-client.component';
import { AuthenticationService } from 'src/@hop/services';
import { DialogCompleteAccountComponent } from 'src/app/pages/pages/dialogs/dialog-menu-complete-account/dialog-menu-complete-account.component';
import { PublicProfileService } from 'src/@hop/services/public-profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hop-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent implements OnInit {
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  user: UserModel;
  userProfile: UserProfileModel;
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store,
    @Inject('environment') public environment,
    private meta: Meta,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private publicUserService: PublicProfileService
  ) {
    this.publicUserService.userProfile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnInit() {}

  openSignupDialog() {
    this.authService.loginPopup$.next('register');
  }
  openSigninDialog() {
    this.authService.loginPopup$.next('login');
  }
  logout() {
    this.authService.logout();
  }
  verifyEmail() {
    this.dialog.open(DialogCompleteAccountComponent, {
      panelClass: 'dialog-open-menu-action',
      data: {
        verifyEmail: true
      }
    });
  }
  setPassword() {
    this.dialog.open(DialogCompleteAccountComponent, {
      panelClass: 'dialog-open-menu-action',
      data: {
        setPassword: true
      }
    });
  }

  protected readonly close = close;
}
