import { ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { ResponseErrorModel } from 'src/@hop/models/responseErrorModel';
import { AuthenticationService, FormUtilsService } from 'src/@hop/services';
import { UserGet, UserState, UserUpdate } from 'src/@hop/state';

@Component({
  selector: 'hop-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent {
  form: UntypedFormGroup;
  email;
  @Output() onSetPassword = new EventEmitter();
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  inputType = 'password';
  visible = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private authService: AuthenticationService,

    private cd: ChangeDetectorRef,
    private formUtilsService: FormUtilsService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      newPassword: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });
    this.form.setValidators(this.checkPasswords);

    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      this.email = user?.email;
    });
  }
  destroyRef = inject(DestroyRef);

  async submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    } else {
      this.authService
        .needsPassword(this.form.controls['newPassword'].value)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: async () => {
            const user = await firstValueFrom(this.user$);
            this.store.dispatch(new UserUpdate({ ...user, needsPassword: false }));
            this.store.dispatch(new UserGet());
            this.onSetPassword.emit(true);
          },
          error: (error: { error: ResponseErrorModel }) => {
            // console.log(error.error);
            if (error.error.errors) {
              this.formUtilsService.setFormErrors(error.error, this.form);
            }
          }
        });
    }
  }

  get f() {
    return this.form.controls;
  }
  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let confirmPass = group.get('passwordConfirm').value;
    let pass = group.get('newPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };
}
