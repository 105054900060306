
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as cookie from 'js-cookie';
import { TranslateService } from '@ngx-translate/core';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class DirtyFormGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private translateService: TranslateService) { }
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        return (component.canDeactivate() || (cookie.get('__cypress.initial') || cookie.get('__iscypress'))) ? true :
            confirm(this.translateService.instant('_general.unsaved-changes'));
    }
}